<template>
    <v-container>
            <form slot="leftContainer" autocomplete="nope" @submit.prevent="addProveedor">
                <v-card>
                    <v-card-title class="grey lighten-4">
                        <b>Agregar Proveedor</b>
                    </v-card-title>
                    <v-card-text>
                        <v-row dense align="center">
                            <v-col cols="12" sm="2">
                                <v-autocomplete
                                    v-model="form.idTipoDocumento"
                                    v-validate="'required'"
                                    :items="tipoDocumento"
                                    item-text="text"
                                    placeholder="DNI"
                                    item-value="id"
                                    :error-messages="errors.collect('form.idTipoDocumento')"
                                    label="Tipo Documento"
                                    data-vv-name="form.idTipoDocumento"
                                    data-vv-as="Tipo"
                                    required
                                />
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field
                                    v-model="form.numeroDocumento"
                                    v-validate="'required'"
                                    data-vv-name="form.numeroDocumento"
                                    data-vv-as="N° Documento"
                                    :error-messages="errors.collect('form.numeroDocumento')"
                                    label="N° Documento"
                                    append-icon="mdi-account-search"
                                    outlined
                                    @click:append="getDatosExternal"
                                    placeholder="20606218100"
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field v-model="form.rubro" label="Rubros" placeholder="Energia, luz" />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model="form.full_name"
                                    v-validate="'required'"
                                    data-vv-name="form.full_name"
                                    data-vv-as="Descripción"
                                    :error-messages="errors.collect('form.full_name')"
                                    label="Descripción"
                                    placeholder="INNOUT SAC"
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model="form.direccion"
                                    data-vv-name="form.direccion"
                                    data-vv-as="Direccion "
                                    :error-messages="errors.collect('form.direccion')"
                                    label="Direccion"
                                    placeholder="Jr jose de la mar 469"
                                />
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-text-field
                                    v-model="form.telefono"
                                    data-vv-name="form.telefono"
                                    data-vv-as="Celular "
                                    :error-messages="errors.collect('form.telefono')"
                                    label="Celular"
                                    placeholder="986193329"
                                />
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-text-field
                                    v-model="form.correo"
                                    v-validate="'email'"
                                    data-vv-name="form.correo"
                                    data-vv-as="Email "
                                    :error-messages="errors.collect('form.correo')"
                                    label="Email"
                                    placeholder="hola@innout.pe"
                                />
                            </v-col>
                            <v-col cols="12" sm="2">
                                <v-btn type="submit" :loading="loading" dark :color="step == 1 ? 'primary' : 'orange'" block>
                                    <b>{{ step == '1' ? 'Agregar' : 'Actualizar' }}</b>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </form>
            <v-card slot="rightContainer" outlined>
                <v-card-title class="grey lighten-4">
                    <b>LISTA DE PROVEEDORES</b>
                    <v-spacer />
                    <v-text-field v-model="search" append-icon="mdi-magnify" label="BUSCAR" single-line hide-details />
                </v-card-title>
                <v-card-text>
                    <v-row dense>
                        <v-col cols="12">
                            <v-data-table multi-sort :search="search" :headers="headers" :items="_listaProveedor" class="elevation-1" :items-per-page="20">
                                <template v-slot:[`item.action`]="{ item }">
                                    <v-icon color="green lighten-2" small @click="editItem(item)">mdi-pencil</v-icon>
                                    <v-icon color="primary" small @click="deleteItem(item)">mdi-delete</v-icon>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
    </v-container>
</template>
<script>
import { mapState, mapGetters } from 'vuex'
import { mixins } from '@/mixins/mixin.js'
import DefaultForm from '@/utils/defaultForm'

export default {
    components: {
    },
    mixins: [mixins],
    $_veeValidate: {
        validator: 'new'
    },
    data() {
        return {
            menu: false,
            search: '',
            tipoGastos: [
                { id: 1, descripcion: 'CONTADO' },
                { id: 2, descripcion: 'TRANSFERENCIA' }
            ],
            step: 1,
            loading: false,
            form: DefaultForm.formProveedor(),
            headers: [
                { text: 'Tipo Doc.', value: 'descTipoDocumento' },
                { text: 'N° Doc.', value: 'numeroDocumento' },
                { text: 'descripcion', value: 'full_name' },
                { text: 'direccion', value: 'direccion' },
                { text: 'celular', value: 'telefono' },

                { text: '', value: 'action', sortable: false }
            ]
        }
    },
    computed: {
        ...mapState('utilities', ['tipoDocumento', 'tipoComprobante', 'tipoBancoDestino']),
        ...mapState('proveedor', ['listaProveedor']),
        _listaProveedor() {
            let newItems = []
            this.listaProveedor.map((x) => {
                if (x && this.tipoDocumento) {
                    ////console.log('tipoDocumento', tipoDocumento)
                    const result = this.tipoDocumento.find((y) => y.id == x.idTipoDocumento)
                    newItems.push({ ...x, descTipoDocumento: result.text })
                }
            })
            return newItems
        }
    },

    async created() {
        await this.getListaProveedor()
    },

    methods: {
        async getDatosExternal() {
            //console.log('this.form.numeroDocumento.length ', this.form.numeroDocumento.length)
            if (this.form.numeroDocumento.length >= 8) {
                try {
                    this.$store.commit('mainUI/OPEN_MODAL', {
                        state: true,
                        text: 'Buscando en Reniec/Sunat',
                        color: 'green'
                    })
                    const resultado = await this.$store.dispatch('proveedor/getClienteExternal', {
                        idTipoDocumento: this.form.idTipoDocumento,
                        numeroDocumento: this.form.numeroDocumento
                    })
                    //console.log('resultado ', resultado)
                    if (resultado.id) {
                        //console.log('find mysql')
                        await this.getListaProveedor(true)

                        this.$store.commit('mainUI/OPEN_BAR', {
                            state: true,
                            text: 'CLIENTE AGREGADO EXITOSAMENTE'
                        })
                    }else{
                        this.form = {
                            ...this.form,
                            full_name: resultado.full_name
                        }
                        //console.log("this.form ",this.form)
                    }
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })
                } catch (error) {
                    this.$store.commit('mainUI/OPEN_MODAL', { state: false })

                    //console.log('error ', error)
                }
            }
        },
        async deleteItem(item) {
            await this.$store.dispatch('proveedor/deleteProveedor', {
                id: item.id
            })
            this.$store.commit('mainUI/OPEN_BAR', {
                state: true,
                text: 'Eliminado satisfactoriamente'
            })
            await this.getListaProveedor(true)
        },
        async addProveedor() {
            this.$validator.validateAll().then(async (result) => {
                if (result) {
                    this.loading = true
                    // //console.log(detalle);
                    let text = 'Agregado exitosamente'

                    if (this.step == 1) {
                        await this.$store.dispatch('proveedor/createCliente', this.form)
                    } else {
                        await this.$store.dispatch('proveedor/updateProveedor', this.form)
                        text = 'Actualizado exitosamente'
                    }
                    this.$store.commit('mainUI/OPEN_BAR', {
                        state: true,
                        text
                    })
                    this.form = DefaultForm.formProveedor()
                    await this.getListaProveedor(true)
                    this.step = 1
                    this.loading = false
                }
            })
        },
        editItem(item) {
            this.form = item
            this.step = 2
            window.scrollTo(0, 0)
        },
        async getListaProveedor(paylaod) {
            await this.$store.dispatch('proveedor/getLista', paylaod)
        }
    }
}
</script>
